html {
    /* font-size: 10px; */
    -webkit-tap-highlight-color: transparent;
}

html {
    font-family: Arial;
    /* -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; */
}

* {
    margin: 0;
    padding: 0;
}


html {
    display: block;
}




body {
    font-family: 'Arial';
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    color: #0e0e0e;



}

#firstpage {
    position: relative !important;
    width: calc(100% - 26px);
    margin: 13px auto 0 !important;
}

@media (max-width: 768px) {
    #firstpage {
        position: relative !important;
        width: calc(100% - 0px) !important;
        margin: 13px auto 0 !important;
    }
}

/* PERFUNDIMI I KODIT PER HTML BODY */

/* STILI CSS PER NAV BAR FILLON KETU */
#myNavbar {
    position: fixed;
    height: 115px;
    width: 100%;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 99;
    padding-top: 25px;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    background-color: #fff;
}

/* .navbar-scrolled {
  background-color: #fff;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  border-bottom: none !important;
} */




.btn-white {
    border-radius: 10px !important;
    background: transparent;
    color: #000;
    border: 2px solid #fff;
    letter-spacing: 3px;
    font-size: 16px;
    border: 2px solid #fff !important;
    transition: all 0.3s ease;

}

.btn-white:hover {
    background-color: #000A64;
    color: #000A64 !important;
    border-color: #000;
}

.btn-black {
    color: #000;
    border: 2px solid #000 !important;
}

.btn-black:hover {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

body.transparent .btn-white {
    background-color: #fff;
    color: #000;
    border-color: #000;
}

#phone-number {
    font-size: 14px;
    color: #000;
    transition: color 0.5s ease;
}

.contacts-phone1 {
    margin-right: -60px !important;
}

/* #phone-number.phone-scrolled {
  color: #000;
} */
.levizja:hover {
    background-color: #000A64 !important;
    color: #fff !important;
    /* Change this to the color you want on hover */

}

.nav-items a span {
    color: #b0b0b0;
    margin-right: 5px;
}

.xstyle {
    font-size: 30px;
    border: none;
    background-color: transparent;
}


@media (max-width: 799px) {
    .navbar-brand {
        cursor: pointer;
        margin-left: 1px !important;

    }
}




@media (max-width: 799px) {
    .menu-icon {
        cursor: pointer;
        position: fixed;
        top: 0;
        right: 0;
        /* z-index: 999; */
    }

    .menu-icon {
        cursor: pointer;
        top: 60px;
        right: 15px;
    }

    /* stili i shtuar per t'i ndryshuar ngjyren e background-it */
    /* #myNavbar.navbar-scrolled menu-icon {
      color: #000;
      background-color: #00;
    } */

    /* Hide menu icon when menu is open and page is scrolled */
    .nav-items.active .menu-icon {
        display: none;
    }
}



@media (max-width: 768px) {

    /* Fshihni kodin */
    .hidden {
        display: none;
    }

    /* Vendosni nje element ne menu qe hap kodin */
    .application {
        display: none;
    }
}

@media (min-width: 1201px) {
    .application {
        left: 50px !important;
        display: none;
    }
}

@media (max-width: 320px) {
    .hidden {
        left: 50px !important;
        display: none;
    }
}

/* Pajisje me ekran me madhesi midis 321px dhe 480px */
@media (min-width: 321px) and (max-width: 480px) {
    .hidden {
        left: 50px !important;
        display: none;
    }
}

/* Pajisje me ekran me madhesi midis 481px dhe 768px */
@media (min-width: 481px) and (max-width: 768px) {
    .hidden {
        left: 50px !important;
        display: none;
    }
}

@media (max-width: 769px) {
    .hidden {
        left: 50px !important;
        display: none;
    }
}

/* Pajisje me ekran me madhesi midis 769px dhe 1024px */


.menu-icon {
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1038px) {
    .menu-icon {
        position: absolute;
        right: 45px;
        top: 50px;
    }
}

@media (min-width: 1200px) and (max-width: 1219px) {
    .menu-icon {
        position: absolute;
        right: 45px;
        top: 50px;
    }
}

.menuicon-scrolled .line {
    background-color: rgba(0, 0, 0, 0.8) !important;
}


@media screen and (min-width: 768px) {
    .hidden1 {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .contacts-phone {
        display: flex !important;
        flex-direction: column !important;
    }

}

/* Styling for the menu icon */
.menu-icon {
    color: #000;
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

/* Styling for the lines in the menu icon */
.line {
    width: 100%;
    height: 2px;
    background-color: #000;
}

/* Styling for the navigation items */
/* Styling for the navigation items */
.nav-items {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
}
.nav-items ul{
    margin-top: 100px !important;
}
.nav-items::before {
    content: "";
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/images/backgroundlogo.webp");
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    z-index: 0;
    top: 15px;
    left: 0;
    pointer-events: none;
    opacity: 0.2;
}

.nav-items.show {
    transform: translateY(0);
}

.nav-items.show::before {
    opacity: 1;
}

.nav-items.show li {
    opacity: 1;
}



/* KETU FILLON STILI CSS PER FOTON ME ANIMATION POSHT NAVBAR */
#home {
    margin-top: 115px !important;
    align-items: center !important;
    padding: 0 10px !important;
}

.gallery {
    display: flex;
}

.image-container {
    width: 33.333333%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-container img {
    /* Add other styles as needed */
    transition: transform 0.2s ease;
}

.image-container:hover img {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
}

.image-container:hover .overlay {
    opacity: 1;
    transform: scale(1.2);
}

.overlay h2,
.overlay p {
    margin: 0;
}

element.style {
    visibility: visible;
    animation-name: fadeInUp;
}

.fadeInUp {
    animation: fadeInUp 2s;
}

.promo {
    font-family: 'Arial' !important;
    position: absolute;
    width: 100%;
    top: 50%;
    color: #fff;
    margin-top: -42.5px;
    padding: 50px 0px 40px 32px;
    background: #000A64;
    z-index: 9;
    opacity: 0.8;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@media only screen and (max-width: 768px) {
    .gallery .image-container:nth-child(n+2) {
        display: none;
    }

    .gallery .image-container:first-child {
        width: 100%;
        height: auto;
    }
}

/* KETU MBARON STILI CSS PER FOTOT POSHT NAVBAR */


/* KODI PER INFOGRAPHICS */
.infographics {
    overflow: hidden;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 80px 0;
    position: relative;
    z-index: 9;
}

.fadeInUp {
    animation: fadeInUp 2s;
}

.infographics .infographic-item__title {
    display: block;
    font-family: 'Arial';
    font-size: 67px;
}

element.style {
    vertical-align: inherit;
}

.infographics small {
    font-family: 'Arial';
    display: block;
    font-size: 15px;
    text-transform: uppercase;
}


@media (max-width: 992px) {
    .infographics {
        padding-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .infographics {
        margin-left: 45px;
        display: grid;
        padding: 30px 0 0 0;
    }
}

/* FUNDI I KODIT PER INFOGRAPHICS */

/* KODI PER PROJECTS */


/* @media (max-width: 767px) {
  .projects h2 {
    padding-left: 20px;
    word-wrap: break-word;

  }
} */


.projects {
    overflow: hidden;
    clear: both;
    padding: 160px 0 80px 0;
    position: relative;
    z-index: 9;
}



.projects h2 {
    font-size: 50px !important;
    padding-left: 10px !important;
    /* background: url(../public/images/1.png) 0 0 no-repeat !important; */
    padding-bottom: 0px !important;
    margin-left: 20px !important;
    text-transform: none;
}

.project-items {
    margin-top: -33px;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project-item {
    position: relative;
    width: 33.1%;
    margin-bottom: 30px;

}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.project-item__image {
    overflow: hidden;
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 30px;
    transition: 0.5s;
    min-height: 300px;
}

.project-item__image img {
    transition: transform 0.6s, filter 0.6s;
    float: left;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.project-item:hover .project-item__image img {
    transform: scale(1.1);
    filter: brightness(50%);
}

.project-item:hover .project-item__info {
    opacity: 1;
    width: 100%;
    pointer-events: auto;
    transition: opacity 1.5s, width 1.5s, pointer-events 0s 1.5s;
}


.project-item .project-item__info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    height: 100%;
    transition: 1.5s;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;
}

.project-item .project-item__info .border {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    border: 1px solid rgba(255, 255, 255, 0.8);
    padding: 20px;
    width: 100%;
    height: 100%;
}

.project-item .project-item__info .project-item__title {
    color: #fff;
    padding-left: 0;
    letter-spacing: 2px;
}

.project-item__title {
    text-transform: uppercase;
    font-family: 'Arial';
    display: block;
    padding-left: 30px;
    font-size: 15px;
    margin-bottom: 5px;
}

.project-item .project-item__info .project-item__city {
    color: #fff;
    padding-left: 0;
    letter-spacing: 2px;
}

.project-item__city {
    font-family: 'Arial';
    display: block;
    padding-left: 30px;
    font-size: 14px;
    margin-bottom: 5px;
}

.project-item .project-item__info .area {
    display: block;
    font-size: 15px;
    color: #fff !important;
    margin-bottom: 10px;
}


.project-item .project-item__info p {
    font-size: 12px;
    color: #fff !important;
}


.project-item .project-item__info .project-bottom {
    overflow: hidden;
    clear: both;
    padding: 20px 0 10px 0;
    border-top: 1px solid #fff;
}

.project-item .project-item__info .project-bottom a {
    font-size: 13px;
    color: #fff;
    letter-spacing: 2.5px;
    padding-right: 30px;
    text-transform: uppercase;
    transition: 0.5s;
}


.load-more-btn {
    width: 80%;
    max-width: 340px;
    margin: 0 auto;
    margin-bottom: 70px;
    z-index: 9999;
    display: block;
    padding: 0 102px !important;
    background: #111111;
    color: #fff;
    border: 2px solid #111111;
    border-radius: 0;
    height: 54px;
    line-height: 54px;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;

}

.load-more-btn:hover {
    background-color: #000A64;
    color: #fff;
    border: 2px solid #000A64;

}

/* Set project items to display as a grid on small screens */
@media only screen and (max-width: 768px) {
    .project-item {
        display: grid;
        width: 100%;
        height: 100%;
    }
}


/* FUNDI I PROJECTS */


/* SERVICES KODI  */

.services {
    overflow: hidden;
    clear: both;
    padding: 120px 0 90px 0;
    background-color: #111111;
    background-size: cover;
}

.anchor {
    position: relative;
    top: -160px;
}

.services h2 {
    font-size: 50px !important;
    padding-left: 10px !important;
    /* background: url(../public/images/1.png) 0 0 no-repeat !important; */
    padding-bottom: 0px !important;
    margin-left: 20px !important;
    text-transform: none;
}

h2.w-color {
    color: #fff;
}


h2 {
    font-family: 'Arial' !important;
    font-size: 76px !important;
    color: #0e0e0e !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    margin-bottom: 50px !important;
}


@media (max-width: 1440px) {
    .service-item .border {
        padding: 20px 0;
    }
}

.service-item .border {
    padding: 40px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}


.c-accordion .row {
    background-color: #000;
    margin-left: -15px;
    margin-right: -15px;
}

.service-item__info {
    padding-left: 27px;
    padding-right: 60px;
}


.service-item__title {
    display: block;
    font-size: 30px;
    color: #fff;
    margin-bottom: 10px;
}

.show-hide-text {
    position: relative;
    padding-bottom: 30px;
}

.show-hide-text:hover .btn-a {
    opacity: 1;
}


@media (max-width: 1440px) {
    .service-item .col-lg-4 {
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .service-price {
        justify-content: flex-start;
    }
}

.service-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 54px;
}

@media (max-width: 1440px) {
    .service-price {
        padding-left: 100px;
    }
}

.service-price .service-price__item {
    margin-bottom: 20px;
}

.service-price .service-price__item .title {
    font-family: 'Arial';
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    letter-spacing: 2px;
    opacity: 0.8;
}

@media (max-width: 1440px) {
    .service-price__item .title {
        margin-right: 30px;
    }
}

.service-price .service-price__item .price {
    display: block;
    font-size: 34px;
    color: #fff;
    min-width: 200px;
}

.service-price .service-price__item .price span {
    font-size: 16px;
    opacity: 0.8;
}

.service-price .service-price__item {
    margin-bottom: 20px;
}




.btn-a {
    font-family: 'Arial';
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 11px;
    color: #000A64;
    letter-spacing: 2px;
    text-transform: uppercase;
    /* opacity: 0; */
    transition: 1s;
}

.service-item p {
    font-size: 15px;
    color: #fff;
    line-height: 22px;
    opacity: 0.8;
}


@media (max-width: 1440px) {
    .service-item .col-lg-4 {
        width: 100%;
    }
}

.show-hide-text:hover .btn-a {
    opacity: 1;
}


.btnshow {
    color: #fff !important;
}

.btn-a:hover,
.btn-a:active,
.btn-a:focus {
    color: #fff;
}

a:focus,
a:active {
    text-decoration: none;
}



.service-btns {
    text-align: center;
    overflow: hidden;
    clear: both;
    padding-top: 50px;
}

.service-btns li {
    width: 25%;
    display: inline-block;

}

.btn.btn-service {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0;
}

.btn.order {
    font-family: 'Arial';
    color: #fff;
    border: 2px solid #fff;
    letter-spacing: 3px;
    font-size: 12px;
}


.service-item p {
    font-size: 15px;
    color: #fff;
    line-height: 22px;
    opacity: 0.8;
}

.order {
    border-radius: 0;
    height: 54px;
    line-height: 54px;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;
}


.order:hover {

    color: #fff !important;
}

#order {
    margin-top: 15px;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
}

.service-btns .btn {
    margin-left: 50px;
    min-width: 200px;
}




.service-item--selected {
    background-color: #000A64;
}

.c-accordion__body {
    color: #fff;
    display: none;
    overflow: hidden;
}

.c-accordion.open .c-accordion__body {
    display: block;
}

.c-accordion .js-show-hide {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.js-show-hide::after {
    content: "\25BC";
    display: inline-block;
    margin-left: 5px;
}

.js-show-hide.is-active::after {
    content: "\25B2";
}


/* PERFUNDIMI I SERVICE */







/* FILLIMI I PROCESS */
/*
@media (max-width: 1440px){
.process {
    padding-left: 80px;
} */

.process {
    overflow: hidden;
    clear: both;
    padding: 140px 65px;
    background-size: cover;
}

.process .process-border {
    border: 3px solid #000;
    /* width: 100%; */
    /* height: 100%; */
    padding: 110px 40px 40px 40px;
}

.fadeInUp {
    animation: fadeInUp 2s;
}


.process h2 {
    text-transform: none !important;
    font-size: 38px !important;
    padding-left: 10px !important;
    /* background: url(../public/images/1.png) 0 0 no-repeat !important; */
    padding-bottom: 0px !important;
    margin-left: 20px !important;
}


.owl-carousel.owl-loaded {
    display: block;
}

/* @media (max-width: 1366px){
.process-slider .owl-theme {
  width: 100%;

}}
@media (max-width: 1440px){
.process-slider .owl-theme {
  width: calc(100% + 190px);

}}
.process-slider .owl-theme {
  width: calc(100% + 280px); */


.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}


.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* .owl-carousel .owl-item img {
  margin-left: 270px !important;
  display: block;
  width: 100%; */


.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl-carousel .item {
    position: relative;
}

.process-slider .item {
    padding: 240px 0 0px 0px;
}

.process-slider .item {
    position: relative;
    text-align: center;
    min-height: 400px;
    padding: 240px 0 0px 0px;
}

@media (max-width: 1366px) {
    .process-image {
        position: relative;
        width: 80%;
        margin-bottom: 30px;
        left: 0;
        text-align: left;
    }
}

.process-image img {
    max-width: 80%;
    transition: 1s;
}


/* @media (max-width: 1366px){
.process-slider .process-item {
    position: relative;
    float: right;
    width: 100%;
    padding: 0px 0 40px 0 !important;
    margin-top: 0px;
}}
.process-slider .process-item {
    position: relative;
    float: right;
    width: calc(100% - 655px);
    padding: 100px 0 40px 0;
    margin-top: -100px; */


.text-left {
    width: 200px;
    font-family: 'Arial';
    position: absolute;
    top: 75%;
    left: 0;
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 55px;
}

.text-right {
    width: 200px;
    font-family: 'Arial';
    position: absolute;
    top: 75%;
    right: 0;
    font-size: 12px;
    text-transform: uppercase;
    padding-right: 55px;
    text-align: right;
}

.text-left span {
    position: absolute;
    left: 0;
    font-family: 'Arial';
    font-weight: 300;
    font-size: 36px;
}

.text-right span {
    position: absolute;
    right: 0;
    font-family: 'Arial';
    font-weight: 300;
    font-size: 36px;
}

.process-slider .title {
    display: block;
    font-size: 32px;
    color: #0f0f0f;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.process-slider p {
    font-size: 16px;
    color: #0f0f0f;
    margin-bottom: 60px;
    padding: 0 25%;
}

.process .process-border .item .btn {
    background: none;
    border: 0px solid #000;
    cursor: default;
}

.process-slider .item .btn {
    margin-bottom: 0px;
}

.item .btn1 {
    width: 80%;
    max-width: 340px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 70px;
    z-index: 9999;
}

.btn1 {
    border-radius: 0;
    height: 54px;
    line-height: 54px;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;
}

.btn1 {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.process-slider .number-bg-1 {
    font-family: 'Arial';
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 530px;
    color: #000;
    opacity: 0.1;
    z-index: 0;
    font-weight: bold;
    line-height: 2460px;
    letter-spacing: -30px;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-theme .owl-nav [class*='owl-'] {
    top: auto;
    bottom: 180px;
    left: 0;
}

.owl-theme .owl-nav [class*='owl-'] {
    width: 82px;
    height: 36px;
    /* background: url(../public/images/leftt.png) 0 50% no-repeat; */
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 80%;
    left: 10px;
    right: auto;
    opacity: 1;
    transition: 0.5s;
    font-size: 0;
}

/* .owl-theme .owl-nav [class*='owl-']:first-child {
    /* background: url(../public/images/leftt.png) 0 50% no-repeat !important; */




.owl-theme .owl-nav [class*='owl-']:last-child {
    left: auto;
    right: 0px;
    background: url(../public/images/right.png) 0 50% no-repeat !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #000;
    border-color: #000;
}


@media (max-width: 767px) {
    .process-image {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        left: 0;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .process-border {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

@media (max-width: 768px) {
    .process {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}


@media (max-width: 580px) {
    .process-image img {
        max-width: 100% !important;
    }
}

@media (max-width: 1200px) {
    .process-slider .item {
        text-align: center !important;
        min-height: 400px !important;
        padding: 0px 0 40px 0 !important;
    }
}

@media (max-width: 767px) {
    .process-slider .number-bg-1 {
        font-size: 40px;
        text-align: left;
        line-height: 1150px;
        letter-spacing: 0;
    }
}

/* @media (max-width: 1200px){
.process-slider .number-bg-1 {
    margin-right: 200px !important;
    font-size: 75px;
    line-height: 1050px;
}} */


@media (max-width: 580px) {
    .process-slider p {
        font-size: 14px;
        color: #0f0f0f;
        margin-bottom: 0px;
        padding: 0 0px;
    }
}


@media (max-width: 767px) {

    .process-item .btn1,
    .process-item .btn-black1 {
        display: none !important;
    }
}

@media (max-width: 768px) {

    .process-item .text-left,
    .process-item .text-right {
        display: none;
    }
}


/* hide navigation arrows on small screens */
@media only screen and (max-width: 767px) {
    .owl-nav {
        display: none;
    }
}

/* adjust width of carousel container */
@media only screen and (max-width: 991px) {
    .owl-carousel {
        max-width: 500px;
        /* adjust as needed */
        margin: 0 auto;
    }
}


@media (min-width: 768px) {
    .owl-dots {
        display: none;
    }
}


/* FUNDI I KODIT PROCESS */


/* FILLIMI I KODIT WHY US */

.why-section {
    text-align: center;
    position: relative;
    overflow: hidden;
    clear: both;
    padding: 120px 0 240px 0;
    background-size: cover;
}

.mask-black {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
}

.why h2 {
    padding-bottom: 110px !important;
    position: relative !important;
    z-index: 9 !important;
}


h2.w-color {
    color: #fff !important;
}



.why-items {
    overflow: hidden;
    clear: both;
    padding: 0 20px 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.why-items .why-item {
    position: relative;
    float: left;
    flex-basis: calc(50% - 50px);
    min-height: 290px;
    transition: 0.5s;
}

.why-item {
    position: relative;
}

.why-items .item-content {
    padding: 70px 30px 30px 90px;
}

.why-items .title {
    position: relative;
    display: block;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #fff;
}

.why-items .title:after {
    content: "";
    display: block;
    position: absolute;
    left: -65px;
    top: 11px;
    width: 36px;
    height: 3px;
}

.why-items p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 20px !important;
}



.anchor-down {
    font-family: 'Arial';
    position: absolute;
    bottom: 33px;
    display: block;
    width: 100%;
    text-align: center;
    letter-spacing: 3px;
    z-index: 9;
}

.anchor-down a {
    font-size: 34px;
    color: #fff !important;
    display: inline-block;
    padding-bottom: 100px;
    text-transform: uppercase;
}

.why-item:hover .item-content {
    background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
    .why-items {
        font-size: 15px;
        width: 100%;
        padding: 0 !important;
        display: grid;
    }
}

@media (max-width: 768px) {
    .why-items {
        font-size: 15px;
        width: 100%;
        padding: 0 !important;
        display: grid;
    }

    .why-items .item-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .why-items p {
        max-width: 100% !important;
        text-align: center;
    }

    .why-item {
        padding: 0 !important;
        flex: 0 1 100%;
        margin: 20px;
        max-width: 100%;
    }

    .why-item:hover .item-content {
        background-color: rgba(0, 0, 0, 0.5);
    }
}


@media screen and (max-width: 768px) {
    .title1 {
        text-align: center;
        font-size: 30px;
    }
}



/* FUNDI I WHY US */


/* Fillimi i contacts */
.contacts {
    overflow: hidden;
    clear: both;
    padding: 30px 0 0 0;
}

.anchor {
    position: relative;
    top: -160px;
}


.contacts h2 {
    text-transform: none;
    font-size: 38px !important;
    padding-left: 10px !important;
    /* background: url(../public/images/1.png) 0 0 no-repeat !important; */
    padding-bottom: 0px !important;
    margin-left: 20px !important;
}

.fadeInUp {
    animation: fadeInUp 2s;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

h2 {
    font-family: 'Arial';
    font-size: 76px;
    color: #0e0e0e;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 50px;
}

.contacts-top {
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .contacts-top .contacts-adress {
        padding-left: 30px !important;

    }

    .contacts-top .contacts-phone {
        margin-left: 0px !important;

    }
}

.contacts-top .contacts-adress {
    padding-left: 185px;
}

.contacts-top .contacts-adress__item {
    display: inline-block;
    margin-right: 40px;
    border-right: 1px solid #cccccc;
    padding: 10px 51px 10px 0 !important;
    height: 70px;
}

.contacts-top .contacts-adress__item .title {
    font-family: 'Arial';
    display: block;
    font-size: 17px;
    text-transform: uppercase;
}

.contacts-top .contacts-adress__item .text {
    font-family: 'Arial';
    display: block;
    font-size: 17px;
}

.contacts-top .contacts-adress__item {
    display: inline-block;
    margin-right: 40px;
    border-right: 1px solid #cccccc;
    padding: 10px 40px 10px 0;
    height: 70px;
}

.contacts-top .contacts-adress__item:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}


.contacts-top .contacts-phone {
    width: 100%;
    padding-top: 50px;
    text-align: left;
    padding-right: 0;
    margin-bottom: 30px;
    margin-left: 150px;
}

.contacts-top .contacts-phone {
    padding-right: 30px;
    position: relative;
    top: -30px;
}

.contacts-top .contacts-phone .phone-item {
    font-family: 'Arial';
    display: block;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 30px;
}


.contacts-top .contacts-phone .kontakti {
    margin-left: 20px;
}

.contacts-phone .kontakti {
    height: 48px;
}

.kontakti:hover {
    color: #fff !important;
    background-color: #000A64 !important;
}

.map iframe {
    height: 100vh;
    width: 100%;
}




/* FUNDI I KODIT CONTACS */

/* FILLIMI I KODIT BLOG */
@media (max-width: 768px) {
    .blog h2 {
        padding-left: 100px !important;
        font-size: 70px !important;
    }
}

.blog {
    padding: 120px 0px 0px;
}

.blog h2 {
    padding-left: 160px;
    padding-bottom: 20px !important;
    margin-left: 30px !important;
}

.fadeInUp {
    animation: 2s ease 0s 1 normal none running fadeInUp;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

h2 {
    font-family: "Arial";
    font-size: 76px;
    color: rgb(14, 14, 14);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 50px;
}

/* FOOTER */
footer {
    overflow: hidden;
    clear: both;
    padding: 55px 15px 45px 0px;
    height: 120px;
    background: #000;
    /* border: 3px solid transparent; add border */
}

footer:hover {
    border-color: #fff;
    /* change border color on hover */
}

ul {

    list-style-type: none;
}

/* footer ul{

}

footer .social-list li {
    display: flex;
    display: inline-block;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 600;
    text-transform: uppercase;

    
} */

footer .social-list li:hover {
    opacity: 1;
    /* add opacity change on hover */
    transform: scale(1.1);
    /* add icon size increase on hover */
}

footer .social-list li img {
    transition: 0.5s;
}

footer .copy {
    float: right;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    line-height: 20px;
}



.buttonhidden {
    display: none;
}


#back {
    position: fixed !important;
    right: 20px !important;
    bottom: 30px !important;
    cursor: pointer !important;
    z-index: 9999999 !important;
    display: none;
}

#back a {
    display: inline-block !important;
    height: 40px !important;
    width: 40px !important;
    text-align: center !important;
    display: block !important;
    color: #fff !important;
    line-height: 40px !important;
    background: #000A64 !important;
    font-size: 15px !important;
    border-radius: 30px !important;
    -webkit-transition: .3s !important;
    -moz-transition: .3s !important;
    -ms-transition: .3s !important;
    -o-transition: .3s !important;
    transition: .3s !important;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 10, 100) !important;
}

#register {
    padding-bottom: 210px;
}

.container5 {
    margin-left: 500px;
    margin-top: 236px;
    max-width: 400px;
    width: 50%;
    background: lightgrey;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 10, 100);
    overflow: hidden;
}



.container5 .forms {
    display: flex;
    align-items: center;
    height: 390px;
    width: 200%;

}

.container5 .form {
    width: 50%;
    padding: 30px;
    background-color: #fff;
}

.container4 .form {
    width: 30%;
    padding: 30px;
    background-color: #fff;
}

.container5.active .login {
    margin-left: -50%;
}

.container5 .signup1 {
    opacity: 0;

}

.container5.active .signup1 {
    opacity: 1;
}

.container5.active .forms {
    height: 480px;
}

.container5 .form .title {
    font-size: 27px !important;
    font-weight: 600 !important;
}

.form .title::before {
    content: '';
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background-color: #4070f4;
    border-radius: 25px;
}

.form .input-field {
    height: 50px;
    width: 100%;
    margin-top: 30px;
}

.input-field input {
    height: 100%;
    width: 100%;
    padding: 0 35px;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid transparent;
    transition: all 0.2s ease;
}

.input-field input:is(:focus, :valid) {
    border-bottom-color: #4070f4;
}

.input-field i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 23px;
    transition: all 0.2s ease;
}

.input-field input:is(:focus, :valid)~i {
    color: #4070f4;
}

.input-field i.icon {
    left: 0;
}

.input-field i.showHidePw {
    right: 0;
    cursor: pointer;
    padding: 10px;
}

.form .checkbox-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.checkbox-text .checkbox-content {
    display: flex;
    align-items: center;
}

.checkbox-content input {
    margin-right: 10px;
    accent-color: #4070f4;
}

.form .text {
    color: #333;
    font-size: 14px;
}

.form a.text {
    color: #4070f4;
    text-decoration: none;
}

.form a:hover {
    text-decoration: underline;
}

.form .button {
    margin-top: 35px;
}

.form .button input {
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 6px;
    background-color: #4070f4;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button input:hover {
    background-color: #265df2;
}

.form .login-signup {
    margin-top: 30px;
    text-align: center;
}


.container4 {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container4 .form {
    width: 400px;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.container4 .form h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.container4 .form form {
    display: flex;
    flex-direction: column;
}

.container4 .form .input-field {
    margin-top: 20px;
}

.container4 .form label {
    margin-bottom: 5px;
    font-weight: bold;
}

.container4 .form input[type="text"],
.container4 .form input[type="password"] {
    width: 100%;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.container4 .form input[type="text"]:focus,
.container4 .form input[type="password"]:focus {
    border-color: #4070f4;
}

.container4 .form .checkbox-content {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.container4 .form .checkbox-content input[type="checkbox"] {
    margin-right: 5px;
}

.container4 .form .button {
    margin-top: 30px;
}

.container4 .form input[type="submit"] {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #4070f4;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.container4 .form input[type="submit"]:hover {
    background-color: #265df2;
}

.container4 .title {
    font-size: 27px !important;
    font-weight: 600 !important;
}

.profile_info {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 10px;
}

.profile_icon {
    font-size: 40px;
}

.profile_author_name {
    margin-bottom: 5px;
}

.profile_info_details a {
    margin-right: 10px;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .process h2 {
        margin-top: 13px;
        margin-left: 20px;
        padding-top: 10px;
        /* background: url(../public/images/3.png) 0 0 no-repeat !important; */
        line-height: 50px !important;
        font-size: 50px !important;
    }

    #contacts-section h2 {
        font-size: 50px !important;
        line-height: 2 !important;
        margin-left: 20px !important;

    }
   

    /* .service-btns li {
      margin-right: 140px !important;
    }
    .service-btns  {
      margin-left: 0 !important;
    } */

    #home {
        padding: 0 !important;
    }

    .order {
        margin-left: 0px !important;

    }

    .service-item .border {
        border: 1px solid #111111 !important;
    }

    .profile_info {
        margin-top: -65px !important;
        margin-left: 370px !important;
    }

    .container5 {
        margin-top: 80px !important;
        max-width: 400px !important;
        width: 100% !important;
        margin-left: 30px !important;
    }

    .container5 .forms {
        display: flex;
        align-items: center;
        height: 438px !important;
        width: 200%;
    }
    .project-page__first-screen__title .link {
        margin-left: 250px;
        color: #fff !important;
        font-size: 14px;
    }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .process h2 {
        margin-left: 20px;
        padding-left: 85px;
        padding-top: 10px;
        /* background: url(../public/images/3.png) 0 0 no-repeat !important; */
        line-height: 50px !important;
        font-size: 50px !important;
    }

    #contacts-section h2 {
        font-size: 50px !important;
        line-height: 2 !important;
        padding-left: 100px !important;
        margin-left: 20px !important;

    }



    #home {
        padding: 0 !important;
    }

    .order {
        margin-left: 0px !important;

    }
    
    

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .process h2 {
        margin-left: 20px;
        padding-left: 85px;
        padding-top: 10px;
        /* background: url(../public/images/3.png) 0 0 no-repeat !important; */
        line-height: 50px !important;
        font-size: 50px !important;
    }

    #contacts h2 {
        font-size: 50px !important;
        line-height: 2 !important;
        padding-left: 100px !important;
        margin-left: 20px !important;

    }

    .order {
        margin-left: 0px !important;
    }

    #home {
        padding: 0 !important;
    }

    /* .service-item .border {
      border: 1px solid #111111 !important;
    } */
    .about-block,
    .awards-block,
    .project-page__inner {
        margin-top: 0;
        padding: 30px 0 0;
    }

    .project-page__title {
        margin-bottom: 60px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }

    .about-block__title__notice,
    .awards-block__title__notice,
    .project-page__title__notice {
        position: relative;
        display: block;
        margin-bottom: 40px;
    }

    .about-block__content__body__title,
    .project-page__descr,
    .team__top__title {
        margin-bottom: 40px;
    }

    h1 {
        font-style: unset !important;
        font-size: 20px;
    }

    .project-page__title__top {
        padding: 0;
        text-align: right;
    }

    .project-page__first-screen__location {
        font-size: 20px;
        width: 50%;
    }

    h5,
    .h5,
    .footer__bottom__right .copyright {
        font-size: 12px;
    }

    .first-screen__title .yellow br,
    .footer__bottom .container,
    .project-page__descr,
    .project-page__descr__left,
    .team__top,
    .contact-page__content .container,
    .pricelist__content__item {
        display: block;
    }

    .project-page__descr__left {
        width: 100%;
    }

    .project-page__descr__right {
        width: 100%;
        margin-top: 20px;
    }

    .project-page__descr__right p {
        font-size: 14px;
        line-height: 20px;
    }

    .project-page__photos__item__two-photos img {
        width: calc(52% - 1rem) !important;
    }
    



}

/*Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (min-width: 992px) {...}*/

/*Extra large devices (large laptops and desktops, 1200px and up) */
/*@media only screen and (min-width: 1200px) {...}*/











.submit {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
}

/* Styling for the navigation items when expanded */
.nav-items.active {
    opacity: 1;
    transform: translateX(0);
}

/* Styling for the navigation links */
.nav-items li {
    list-style: none;
    margin: 10px 0;
}

.nav-items.open {
    transform: translateY(0);
}

.nav-items li a {
    color: #000;
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    margin-left: 40px;
}

/* Styling for the close button */
.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    color: #000;
    border: none;
    font-size: 24px;
    cursor: pointer;
}


@media (max-width: 767px) {
    .main-block img {
        min-height: 700px;
    }
}

/* KETU MBARON STILI CSS PER NAVBAR */


/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(100%);
    /* Change arrow color to black */
}

.carousel-indicators li {
    background-color: black !important;
    /* Change indicator color to black */
}

/* Add this CSS to your stylesheet */

.carousel-indicators .active {
    background-color: black !important;
    /* Change active indicator color to black */
}

#page {
    width: calc(100% - 0px) !important;
    margin: 0px auto 0 !important;
}


@media (min-width: 1024px) {
    .project-page__first-screen {
        background-attachment: fixed;
    }
}

.project-page__first-screen {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 4rem;
    position: relative;
    color: var(--white);
}

.project-page__first-screen::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
}

.project-page__first-screen {
    background-size: cover;
}

.project-page__first-screen .container1 {
    position: relative;
    z-index: 2;
}

.container1 {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
}

.project-page__first-screen__location {
    margin-bottom: 15vh;
}

.text-uppercase {
    text-transform: uppercase;
}

h4,
.h4 {
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: -0.05em;
    line-height: .92;
}

.justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.project-page__photos__item__two-photos img {
    width: calc(51% - 1rem) !important;
}


.project-page__first-screen__name span {
    display: block;
    padding-left: 10vw;
}

sup {
    font-size: 0.6em;
    vertical-align: text-top;
    padding-left: 0.2em;
}

.project-page__first-screen__title .link {
    color: #fff !important;
    font-size: 14px;
}

.text-uppercase {
    text-transform: uppercase;
}

a {
    color: currentColor;
    -webkit-transition: all .2s;
    transition: all .2s;
}

a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none !important;
}

a,
a:hover {
    text-decoration: none;
}

.project-page__inner {
    margin-top: 0;
    padding-top: 18rem;
}

.project-page__inner {
    padding: 3rem 0;
    background-color: var(--white);
    margin-top: 15rem;
    margin-bottom: 6rem;
}

.container1 {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
}

.description-part {
    margin-left: 200px; /* Adjust the margin value according to your needs */
}


.project-page__title {
    position: relative;
    line-height: 1.125;
    margin-bottom: 3em;
}

h3,
.h3 {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.125;
}

.project-page__descr {
    margin-bottom: 15rem;
}

.justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.project-page__descr {
    margin-top: 50px;

}

.project-page__descr__left {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.project-page__descr__left__item:not(:last-child) {
    margin-bottom: 6rem;
}

.project-page__descr__left__item {
    width: 22rem;
}

.project-page__descr__left__item .member-status {
    line-height: 1;
    margin-bottom: 1.1em;
    font-weight: 400;
}

.fz-12 {
    font-size: 12px;
}

.project-page__descr__left__item .member-name {
    color: var(--grey);
}

.text-uppercase {
    text-transform: uppercase;
}

h5,
.h5 {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1;
}

.project-page__descr__left__item .member-name span {
    display: block;
    margin-bottom: 5px;
}

.project-page__descr__left__item {
    width: 22rem;
}

.project-page__descr__left__item .member-status {
    line-height: 1;
    margin-bottom: 1.1em;
    font-weight: 400;
}

.fz-12 {
    font-size: 12px;
}

h5,
.h5 {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1;
}

.project-page__descr__left__item .member-name span {
    display: block;
    margin-bottom: 5px;
}

.project-page__descr__left__item .member-name span {
    display: block;
    margin-bottom: 5px;
}

.project-page__descr__right {
    width: 100% !important;
    -webkit-column-count: 2 !important;
    column-count: 2 !important;
    -webkit-column-gap: 3rem !important;
    column-gap: 3rem !important;
    -webkit-column-break-inside: avoid !important;
    break-inside: avoid-column !important;
}


.project-page__descr__right p:not(:last-child) {
    margin-bottom: 1.5em !important;
}

.project-page__descr__right p {
    color: #000 !important;
    display: flex !important;
    font-size: 20px !important;
    line-height: 1.3 !important;
}

.project-page__photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -1rem;
}

.project-page__photos__item {
    margin-bottom: 2rem;
    width: 100%;
}

.project-page__photos__item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

img {
    max-width: 100%;
}

.new_sat_prof_nf .project-page__photos__item__two-photos {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.project-page__photos__item__two-photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.new_sat_prof_nf .project-page__photos__item__two-photos img {
    margin-bottom: -1rem;
}


.project-page__photos__item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

img {
    max-width: 100%;
}

.related {
    padding: 6rem 0 8rem;
}

.container1 {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
}

.related__top {
    margin-bottom: 4.8rem;
}

.justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

h2,
.h2 {
    font-size: 1.5rem;
    font-size: 4.8rem;
    /* text-transform: uppercase; */
    font-weight: 600;
    letter-spacing: -0.04em;
    line-height: 0.9166;
}

.related-projects-arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.related-projects-arrows .prev-arrow {
    margin-right: 2rem;
}

.related-projects-arrows .slick-arrow {
    position: relative;
    width: 6rem;
    height: 6rem;
    color: var(--text-color);
    cursor: pointer;
    -webkit-transition: all .4s;
    transition: all .4s;
    z-index: 2;
}

.related-projects-arrows .slick-arrow svg {
    width: 100%;
    height: 100%;
}

.related-projects-arrows .slick-arrow {
    position: relative;
    width: 6rem;
    height: 6rem;
    color: var(--text-color);
    cursor: pointer;
    -webkit-transition: all .4s;
    transition: all .4s;
    z-index: 2;
}

.related__slider {
    margin: 0 -1rem;
}

.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.text1 {
    color: #fff;
    font-size: 25px !important;
}

@media only screen and (max-width: 600px) {

    .about-block,
    .awards-block,
    .project-page__inner {
        margin-top: 0;
        padding: 30px 0 0;
    }

    .project-page__title {
        margin-bottom: 60px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }

    .about-block__title__notice,
    .awards-block__title__notice,
    .project-page__title__notice {
        position: relative;
        display: block;
        margin-bottom: 40px;
    }

    .about-block__content__body__title,
    .project-page__descr,
    .team__top__title {
        margin-bottom: 40px;
    }

    .text1 {
        font-size: 16px !important;
    }

    .text {
        font-size: 20px !important;
    }

    .project-page__title__top {
        padding: 0;
        text-align: right;
    }

    .project-page__first-screen__location {
        font-size: 20px;
        width: 50%;
    }

    h5,
    .h5,
    .footer__bottom__right .copyright {
        font-size: 12px;
    }

    .first-screen__title .yellow br,
    .footer__bottom .container,
    .project-page__descr,
    .project-page__descr__left,
    .team__top,
    .contact-page__content .container,
    .pricelist__content__item {
        display: block;
    }

    .project-page__descr__left {
        width: 100%;
    }

    .project-page__descr__right {
        width: 100%;
        margin-top: 20px;
    }

    .project-page__descr__right p {
        font-size: 15px !important;
        line-height: 20px;
    }

    .project-page__photos__item__two-photos img {
        width: calc(52% - 1rem) !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
.sat_link_call_popup {
    padding: 0 !important;
    width: 90%;
    max-width: 440px;
    margin: 0 auto;
}

.feedback-content {
    background-color: #fff;
    padding: 30px;
}

.feedback-content .h2 {
    text-transform: inherit;
    font-weight: 500;
    line-height: 1.125;
    margin-bottom: 0.3em;
}

.feedback-content p {
    font-size: 1.8rem;
    line-height: 1.33;
    max-width: 330px;
    margin-bottom: 1em;
}

.input-wrapper {
    border: none !important;
    margin-bottom: 20px;
}

.input-wrapper input {
    padding: 12px 20px;
    border: none !important;
    display: block;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: black;
    appearance: none !important;
}

.feedback-content {
    background-color: #fff;
    padding: 30px;
}

.feedback-content__submit {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    background-color: #1F1F1F;
    color: #fff;
    cursor: pointer;
    transition: all .3s;
    appearance: none;
    border: none;
    appearance: none;
}

.map-container {
    height: 400px;
    width: 100%;
    /* max-width: 800px;
    margin: 0 auto; */
}

.map-container .leaflet-container {
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .map-container {
        margin-top: 70px !important;
    }
}

.process-border1 {
    border: 1px solid #000;
    /* width: 100%; */
    /* height: 100%; */
    padding: 0px 0px 0px 0px;
}

.process1 {
    overflow: hidden;
    clear: both;
    padding: 20px 1px;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .process-border1 {
        margin-top: -60px;
        border: none;
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000A64;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 32px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    z-index: 9999;
    border-radius: 50%;
    transition: opacity 0.3s;
}

.scroll-to-top:hover {
    background-color: #555;
}


.custom-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000A64;
    color: white;
    padding: 15px;
    border-radius: 5px;
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-alert span {
    margin-right: 10px;
}
.custom-alert1 {
    position: fixed;
    top: 50px;
    margin-right: 30px;
    margin-left: 1000px;
    /* transform: translateX(-50%); */
    background-color: #000A64;
    color: white;
    padding: 15px;
    border-radius: 5px;
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-alert2 {
    position: fixed;
    top: 50px;
    margin-right: 30px;
    margin-left: 1000px;
    /* transform: translateX(-50%); */
    background-color: red;
    color: white;
    padding: 15px;
    border-radius: 5px;
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-alert span {
    margin-right: 10px;
}
/* You can customize this styling according to your preference */
textarea {
    width: 100%;
    height: 80px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    resize: none;
}



.footer__bottom__left__title {
    margin-bottom: 3em;
}

.fz-12 {
    font-size: 12px;
}

.social--horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.social {
    font-size: 15px;
    line-height: 3;
    letter-spacing: -0.03em;
    font-weight: 600;
    text-transform: uppercase;
}

.social--horizontal li:not(:last-child) {
    margin-right: 1em;
}

.social li:not(:last-child) {
    margin-bottom: 0.4em;
}

.social--horizontal li {
    margin-bottom: 0 !important;
}

.social a:hover {
    color: #000A64 !important;
}

.footer__bottom__right .copyright {
    font-size: 1.4rem;
    text-transform: uppercase;
}

.footer__bottom__right {
    color: #fff;
    float: right !important;
    margin: 0;
    /* Remove default margin */
    font-size: 14px;
    line-height: 3;
    /* Adjust font size as needed */
    text-align: right;
    /* Align text to the right */
}
/* 

.social--horizontal1 {
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
} */
.test1{
    margin-top: 100px;
}
.social1{
    color: #000 !important;
    font-size: 15px;
    letter-spacing: -0.03em;
    font-weight: 600;
    text-transform: uppercase;
}


.social--horizontal1 li:not(:last-child) {
    margin-right: 1em;
}

.social1 li:not(:last-child) {
    margin-bottom: 0.4em;
}

.social--horizontal1 li {
    margin-bottom: 0 !important;
}

.header__link {
    margin-left: 5px;
    font-size: 30px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.03em;
    position: relative;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.arrow-before::before {
    content: '→';
    display: inline-block;
    vertical-align: middle;
    color: currentColor;
    -webkit-transform: translateY(-0.075em);
    transform: translateY(-0.075em);
    margin-right: 0.2em;
  }

.contacts-phone1 span:hover{
    color: #000A64 !important;
}
.contacts-phone span:hover{
    color: #000A64 !important;
}

.pointer{
    cursor: pointer;
}
/*  */
.loader{
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg{
    width: 100%;
}

.loading-text{
    width: 100%;
    font-size: 20px;
    margin-left: 120px;
    font-weight: bold;
    color: #000;
    transform: translate(.4rem);
    margin-top: 1rem;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .svg-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media only screen and (max-width: 768px) {
    .footer {
        overflow: hidden;
        clear: both;
        padding: 25px 15px;
        height: auto;
        background: #000;
    }
}

.x-icon {
  font-size: 15px; /* Adjust size as needed */
  line-height: 1;
}
/* Add this CSS to your stylesheet */
@media only screen and (max-width: 768px) {
    .slick-dots{
        position: relative !important;
        margin: 20px !important;
        margin-top: -45px !important;
        color: black !important; /* Set the color of the dots */
    }
    .description-part {
        margin-left: 0 !important;
        font-size: 14px;
    }
}


@media only screen and (max-width: 600px) {
    .custom-alert1 {
        margin-top: 50px;
        position:fixed;
        margin-left: 70px;
        background-color: #000A64;
        color: white;
        padding: 15px;
        border-radius: 5px;
        z-index: 10010; /* Ensure it's above the modal */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-alert2 {
        margin-top: 50px;
        position:fixed;
        margin-left: 70px;
        background-color: red;
        color: white;
        padding: 15px;
        border-radius: 5px;
        z-index: 10010; /* Ensure it's above the modal */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .contacts-top .contacts-adress__item {
        border: none !important;
    }
}
